import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Dialog, Modal } from "@mui/material";
import {post, URLs, DB} from './admin/API/API'

export default function Work(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [itemData, setItemData] = useState([])
    useEffect(()=>{
        post(URLs.GET_JSON_FILE, DB.WORK_IMAGES, (res) => {
            console.log (res)
            setItemData(res)
        })
    },[])
    return (
        <Box>
            <ImageList
                sx={{ width: 1, height: "calc(100% - 300px)", margin: "auto" }}
                cols={2}
                gap={0}
                rowHeight={500}
            >
                {itemData.map((item, index) => (
                    <ImageListItem key={item.src} >
                        <img
                            srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                            // src={`${item.src}`}
                            alt={item.title}
                        />

                        <Box
                            sx={{
                                position: "absolute",
                                width: 1,
                                height: 1,
                                bgcolor: "skyblue",
                                opacity: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "3rem",
                                fontWeight: 700,
                                "&:hover": {
                                    opacity: 0.7,
                                },
                            }}
                            onClick={() => {
                                setDialogOpen(true);
                                setSelectedIndex(index);
                            }}
                        >
                            {item.title}
                        </Box>
                    </ImageListItem>
                ))}
            </ImageList>
            {/* <Dialog
                open={dialogOpen}
                fullWidth={true}
                maxWidth={"lg"}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <Box sx={{ width: 500, height: 500 }}>
                    {selectedIndex !== -1 && (
                        <src
                            width={"80%"}
                            src={itemData[selectedIndex].src}
                            style={{ border: "5px solid white" }}
                        />
                    )}
                </Box>
            </Dialog> */}
            <Modal
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <Box>
                    {selectedIndex !== -1 && (
                        <img
                            width={"80%"}
                            src={itemData[selectedIndex].src}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                border: "5px solid white",
                            }}
                        />
                    )}
                </Box>
            </Modal>
        </Box>
    );
}
