import {
    Box,
    Button,
    InputBase,
    styled,
    TextField,
    InputAdornment,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { post } from "./API/API";

import {
    FullFlexCenterStyle,
    LeftBarContainerStyle,
    LeftBarStoryStyle,
    SearchBarContainerStyle,
    SearchBarTextFieldStyle,
    SearchStyle,
} from "../Style/Ai_style";

export const Style = styled(Box)({
    border: "1px solid white",
    borderRadius: "20px",
    px: "16px",
    py: "2px",
    width: 0.6,
});

export function LeftBar(props) {
    return <LeftBarContainerStyle></LeftBarContainerStyle>;
}

export function Content(props) {
    const [response, setResponse] = useState("응답을 기다리고 있습니다");
    const [param, setParam] = useState("");
    const [url, setUrl] = useState("");
   
    return (
        <Box
            sx={{
                width: "calc(100%)",
                backgroundColor: "#eee",
                position: "relative",
            }}
        >
            <Box> API TEST </Box>
            <TextField  size='small' label="url" value={url} onChange={(e) => setUrl(e.target.value)} />
            <TextField  size='small' label='param' value={param} onChange={(e) => setParam(e.target.value)} />
            <Button
                onClick={() => {
                    if (url && param) {
                        post(url, param, (res) => {
                            console.log (res)
                            setResponse(res)
                        })}
                    }
                }
                    
            >
                요청하기
            </Button>
            <Box>{response}</Box>

            
        </Box>
    );
}

export default function APIs(props) {
    return (
        <Box
            sx={{
                width: "100%",
                height: "calc(100vh - 282px)",
                display: "flex",
            }}
        >
            <Content />
        </Box>
    );
}
