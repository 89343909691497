import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { post, URLs } from "./admin/API/API";

export default function Contact(props) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");

    return (
        <Box>
            {/* 1 */}
            <Box sx={{ position: "relative" }}>
                <Box
                    sx={{
                        width: "100%",
                        height: 550,
                        backgroundImage: "url(./photo/111.jpeg)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                    }}
                ></Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "10%",
                        left: "100px",
                        fontSize: "2.5rem",
                        fontWeight: 700,
                        color: "white",
                    }}
                >
                    CONTACT US
                </Box>
            </Box>
            {/* 2 */}
            <Box sx={{ height: 350, bgcolor: "white" }}>
                <Box sx={{ textAlign: "center" }}>
                    <Box
                        sx={{
                            fontSize: "2.5rem",
                            fontWeight: 700,
                            pb: 2,
                            pt: 10,
                        }}
                    >
                        CONTACT
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box>
                            {props.data?.contact?.address}
                        </Box>
                        <Box>{props.data?.contact?.phone}</Box>
                        <Box>{props.data?.contact?.email}</Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ display: "flex", gap: 2, pt: 3 }}>
                        <InstagramIcon />
                        <YouTubeIcon />
                        <TextsmsIcon />
                    </Box>
                </Box>
            </Box>
            {/* 3 */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: 550,
                    bgcolor: "white",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: 500,
                    }}
                >
                    <TextField
                        label="Name*"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label="Email*"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <TextField
                        multiline
                        rows={9}
                        label="벽면크기,지역,업종,연락처를 꼭 포함해서 써주세요"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            sx={{
                                backgroundColor: "black",
                                color: "white",
                                width: 150,
                                height: 40,
                            }}
                            onClick={()=>{
                                post(URLs.SEND_VOC, {
                                    subject: subject,
                                    content: `${name}\n${email}\n${text}`
                                }, (e) => console.log(e))
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
