import { Box } from "@mui/material";
import React from "react";

export default function Footer(props) {
    return (
        <Box>
            {/* 7 */}
            <Box
                sx={{
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    borderTop: "1px solid black",
                    p: 3,
                }}
            >
                <Box
                    sx={{
                        fontSize: 30,
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        py: 3,
                        pt: 5,
                    }}
                >
                    {props.data?.common?.slogan2}
                </Box>
                <Box onClick={props.switchAdmin}>{props.data?.common?.company}</Box>
                <Box sx={{ p: 2, pb: 5 }}>{props.data?.common?.address}</Box>
            </Box>
        </Box>
    );
}
