import React from "react";

import { Box, Button } from "@mui/material";

export default function SaveUpdates(props) {
    return (
        <Box
            sx={{
                position: "fixed",
                top: props.top || 100,
                left: "5%",
                width: 0.9,
                height: 100,
            }}
        >
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    opacity: 0.7,
                    bgcolor: "black",
                }}
            ></Box>
            <Box
                sx={{
                    width: 1,
                    height: 1,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        color: "white",
                        fontSize: "1.5rem",
                        fontWeight: 800,
                    }}
                >
                    {props.title}
                </Box>

                <Button
                    variant="contained"
                    sx={{ ml: 2 }}
                    size="small"
                    onClick={() => props.onClick()}
                >
                    {props.buttonName}
                </Button>
            </Box>
        </Box>
    );
}
