export const baseUrl = "https://be.bao-d.co.kr/"
export const URLs = {
    GET_SITE_FILE: "get_site_file",
    SAVE_SITE_FILE: "save_site_file",
    GET_TEXT_FILE: "get_text_file",
    GET_JSON_FILE: "get_json_file",
    SAVE_JSON_FILE: "save_json_file",
    APPEND_JSON_CONTENT: "append_json_content",
    DELETE_JSON_CONTENT: "delete_json_content",
    // GET_IMAGE_FILE: "get_image_file",
    SAVE_IMAGE_FILE: "save_image_file", 
    SEND_VOC:"send_voc",
    CHAT: "chat",
    QUERY:'query'
}

export const DB = {
    SITE: "data.json",
    STORY: "stories.json",
    WORK_IMAGES: "work_images.json"
}

export function post(url, data, onReceive) {
        fetch(baseUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data }),
        })
            .then((res) => {
                if (res.ok)
                    return res.text()
                throw new Error('Something went wrong.');
            })
            .then((res_text) => {
                let res_basic
                try {
                    res_basic = JSON.parse(res_text)
                    if (typeof res_basic !== 'object')
                        return res_basic
                }
                catch (e) {
                    return res_text
                }
                let res_result 
                try {
                    res_result = JSON.parse(res_basic.result)
                    if (typeof res_result === 'object')
                        return res_result
                }
                catch (e) {
                    return res_basic.result
                }
            }
        )
        .then((res) => onReceive(res))
        .catch((e) => {
            console.log (e)
        })

}

export function post_file (url, data, onReceive) {
    fetch(baseUrl + url, {
        method: "POST",
        // headers: {
        //     "Content-Type": "multipart/form-data",
        // },
        body: data,
    })
        .then((res) => {
            if (res.ok)
                return res.text()
            else
                console.log (res)
            throw new Error('Something went wrong.');
        })
        .then((res_text) => {
            let res_basic
            try {
                res_basic = JSON.parse(res_text)
                if (typeof res_basic !== 'object')
                    return res_basic
            }
            catch (e) {
                return res_text
            }
            let res_result 
            try {
                res_result = JSON.parse(res_basic.result)
                if (typeof res_result === 'object')
                    return res_result
            }
            catch (e) {
                return res_basic.result
            }
        }
    )
    .then((res) => onReceive(res))
    .catch((e) => {
        console.log (e)
    })

}
