import { Box, Button, styled, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";

// SearchBar
export const SearchBarContainerStyle = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "8px",
    paddingBottom: "8px",
    backgroundColor: "#a36410",
});

export const SearchStyle = styled(Box)({
    color: "white",
    fontWeight: 700,
});

export const SearchBarTextFieldStyle = styled(Box)({
    border: "1px solid white",
    borderRadius: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "2px",
    paddingBottom: "2px",
    width: "60%",
});

// LeftBar
export const LeftBarContainerStyle = styled(Box)({
    width: "250px",
    backgroundColor: "#222",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
    paddingTop: "8px",
});

export const LeftBarStoryStyle = styled(Box)({
    width: "calc(230px - 16px)",
    height: "30px",
    color: "white",
    fontWeight: 700,
    backgroundColor: "#444",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "16px",
    alignItems: "center",
});

// Content
export const FullFlexCenterStyle = styled(Box)({
    width: "100%",
    height: "100%",
    color: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
});
