import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TextsmsIcon from "@mui/icons-material/Textsms";
import SaveUpdates from "./SaveUpdates";

import { DB, post, URLs } from "./API/API";

const originCompanyData = {
    address: " 서울특별시 마포구 독막로15길 12 (상수동 145-12)",
    phone: " 02-365-2039 | 010-4589-2039",
    email: "daygraphykr@naver.com",
};

export default function Contact(props) {
    const [companyData, setCompanyData] = useState(props.data?.contact);

    return (
        <Box>
            {/* 1 */}
            <Box sx={{ position: "relative" }}>
                <Box
                    sx={{
                        width: "100%",
                        height: 550,
                        backgroundImage: "url(./photo/111.jpeg)",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundAttachment: "fixed",
                    }}
                ></Box>
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "10%",
                        left: "100px",
                        fontSize: "2.5rem",
                        fontWeight: 700,
                        color: "white",
                    }}
                >
                    CONTACT US
                </Box>
            </Box>
            {/* 2 */}
            <Box sx={{ height: 450, bgcolor: "white" }}>
                <Box sx={{ textAlign: "center" }}>
                    <Box
                        sx={{
                            fontSize: "2.5rem",
                            fontWeight: 700,
                            pb: 2,
                            pt: 10,
                        }}
                    >
                        CONTACT
                    </Box>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                        <TextField
                            sx={{
                                display: "flex",
                                width: "500px",
                            }}
                            value={props.data?.contact?.address}
                            onChange={(e) =>
                                props.changeData("contact", "address", e.target.value)
                            }
                        />

                        <TextField
                            sx={{
                                display: "flex",
                                width: "500px",
                            }}
                            value={props.data?.contact?.phone}
                            onChange={(e) =>
                                props.changeData("contact", "phone", e.target.value)
                            }
                        />

                        <TextField
                            sx={{
                                display: "flex",
                                width: "500px",
                            }}
                            value={props.data?.contact?.email}
                            onChange={(e) =>
                                props.changeData("contact", "email", e.target.value)
                            }
                        />

                        {/* 클릭하면 박스의 글자가 텍스트 필드 입력으로 바뀌게 */}
                        {/* {click ? (
                            <Box onClick={() => setClick(false)}>
                                <Box>
                                    서울특별시 마포구 독막로15길 12 (상수동
                                    145-12)
                                </Box>
                                <Box>02-365-2039 | 010-4589-2039</Box>
                                <Box>daygraphykr@naver.com</Box>
                            </Box>
                        ) : (
                            <>
                                {companydatas.map((cdt, index) => (
                                    <TextField
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            width: "500px",
                                        }}
                                        defaultValue={cdt.data}
                                        onChange={(e) =>
                                            setClick(e.target.value)
                                        }
                                    />
                                ))}
                            </>
                        )} */}
                    </Box>
                </Box>
                <Box
                    sx={{
                        height: 100,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ display: "flex", gap: 2, pt: 3 }}>
                        <InstagramIcon />
                        <YouTubeIcon />
                        <TextsmsIcon />
                    </Box>
                </Box>
            </Box>

            <SaveUpdates
                title="관리자 페이지 입니다."
                buttonName="수정사항 저장하기"
                onClick={() => {
                    post(URLs.SAVE_SITE_FILE, {
                        file_name:DB.SITE,
                        content: props.data
                    }, (res) => console.log (res))
                }}
            />
        </Box>
    );
}
