import React, { useState, useEffect, useRef } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Dialog, Modal, Button, TextField } from "@mui/material";
import SaveUpdates from "./SaveUpdates";
import { post, post_file, URLs, DB } from "./API/API";


export default function Work(props) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [fileDialogOpen, setFileDialogOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [itemData, setItemData] = useState([])
    const inputRef = useRef()
    
    const [selectedImage, setSelectedImage] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const [imageTitle, setImageTitle] = useState("")
    
    useEffect(()=>{
        post(URLs.GET_JSON_FILE, DB.WORK_IMAGES, (res) => {
            console.log (res)
            setItemData(res)
        })
    },[])


    function handleFileChange (e) {
        setFileDialogOpen(true)
        setSelectedImage(URL.createObjectURL(e.target.files[0]))
        setSelectedFile(e.target.files[0])
    }

    function uploadFile (e) {
        setFileDialogOpen(false)

        const formData = new FormData();
        formData.append("file", selectedFile);

        post_file(URLs.SAVE_IMAGE_FILE, formData, (res) => {
            post(URLs.APPEND_JSON_CONTENT, {file_name:DB.WORK_IMAGES, content:{src: res, title: imageTitle}}, (res)=> {
                console.log (res)
                setItemData(res)
                
            })
        })
    }

    // const uploadFiles = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();
    //     formData.append("file", uploadingFile);
    //     post_file(URLs.SAVE_IMAGE_FILE, formData, (res) => {
    //         post(URLs.APPEND_JSON_CONTENT, {file_name:DB.WORK_IMAGES, content:{src: res, title: "test"}}, (res)=> {
    //             console.log (res)
    //         })
    //     })
    // }


    return (
        <Box>
            <ImageList
                sx={{ width: 1, height: "calc(100% - 300px)", margin: "auto" }}
                cols={2}
                gap={0}
                rowHeight={500}
            >
                {itemData.map((item, index) => (
                    <ImageListItem key={item.src}>
                        <img
                            srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.src}?w=164&h=164&fit=crop&auto=format`}
                            // src={`${item.src}`}
                            alt={item.title}
                        />

                        <Box
                            sx={{
                                position: "absolute",
                                width: 1,
                                height: 1,
                                bgcolor: "skyblue",
                                opacity: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "3rem",
                                fontWeight: 700,
                                "&:hover": {
                                    opacity: 0.7,
                                },
                            }}
                            onClick={() => {
                                setDialogOpen(true);
                                setSelectedIndex(index);
                            }}
                        >
                            {item.title}
                            <Button variant="contained" sx={{ bgcolor: "red" }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    post(URLs.DELETE_JSON_CONTENT, {
                                        file_name: DB.WORK_IMAGES,
                                        content: [item.id]
                                    },
                                    (res) => {
                                        setItemData(res)
                                    })
                                }}
                            >
                                삭제
                            </Button>
                        </Box>
                    </ImageListItem>
                ))}
            </ImageList>

            <Modal
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <Box>
                    {selectedIndex !== -1 && (
                        <img
                            width={"80%"}
                            src={itemData[selectedIndex].src}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                border: "5px solid white",
                            }}
                        />
                    )}
                </Box>
            </Modal>

            <Dialog open={fileDialogOpen} onClose={() => {setFileDialogOpen(false)}}>
                <Box sx={{p:1, display:'flex', flexDirection:'column', gap:2}}>
                    <img src={selectedImage} style={{width:'100%', objectFit:'cover'}}/>
                    <TextField label="제목을 입력해주세요" size="small" value={imageTitle} onChange={(e) => setImageTitle(e.target.value)} />
                    <Box>
                        <Button onClick={uploadFile}> 업로드 </Button>
                        <Button onClick={() => setFileDialogOpen(false)}> 취소 </Button>
                    </Box>
                </Box>
            </Dialog>

            

            <SaveUpdates
                title="관리자 페이지 입니다."
                buttonName="이미지 업로드 하기"
                onClick={() => {
                    inputRef.current?.click();
                }}
            />



            <input ref={inputRef} type="file" onChange={handleFileChange} style={{display:'none'}} onClick={e=>{e.target.value = null}}/>
            {/* <Button onClick={(e) => uploadFiles(e)}> SAVE </Button> */}
        </Box>
    );
}
